<template>
  <span
    :class="`text-${variant}`">
    <i v-if="status.icon" :class="['small','bx',status.icon]" />
    {{$t(status.label)}}
    <template v-if="!value">
      <b-badge class="mr-1" v-for="(badge,i) in item.badges" :key="i" :variant="badge.variant">
        <i v-if="badge.icon" :class="['bx',badge.icon]" />
        <span v-if="badge.label">{{$t(badge.label)}}</span>
      </b-badge>
    </template>
  </span>
</template>

<script>
import Resource from '@/services/Resource'
export default {
  props: {
    item: {
      type: Resource,
      required: true
    },
    value: {
      type: [Number,String],
      default: null
    }
  },
  computed: {
    status() {
      return this.item.constructor.statuses.find(s => s.code == (this.value ?? this.item.status))
    },
    variant() {
      switch(true) {
        case !!this.status.variant:
          return this.status.variant
        case this.status.code <= 0:
          return 'muted'
        case this.status.code >= 100:
          return 'success'
        default:
          return 'info'
      }
    },
  }
}
</script>