<template>
  <b-button 
    :disabled="running"
    @click="performAction"
    v-bind="$attrs"
  >
    <div :class="{flashing: running}">
      <slot></slot>
    </div>
  </b-button>
</template>

<script>
export default {
  props: {
    handler: Function,
  },
  data() {
    return {
      running: false,
    }
  },
  methods: {
    async performAction(event) {
      this.running = true
      try {
        await this.handler(event)
      } catch (error) {
        console.error(error)
      } finally {
        this.running = false
      }
    }
  }
}
</script>