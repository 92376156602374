<template>
  <b-modal 
    centered
    ref="modal"
    v-if="item"
  >
    <template #modal-title>
      <i class='bx bx-package'></i>
      {{item.barcode}}
      <status v-bind="{item}" />
    </template>

    <dl style="overflow-wrap: anywhere">
      <div class="row row-cols-1 row-cols-sm-2">
        <div class="col">
            <value label="shipments.consignee" icon="bx-purchase-tag" :value="item.$shipment.name" />
        </div>
        <div class="col">
            <value label="common.phone" icon="bx-phone" :value="`+${item.$shipment.full_phone_number}`" />
        </div>
        <div class="col">
            <value label="common.email" icon="bx-envelope" :value="item.$shipment.email" />
        </div>
        <div class="col">
            <value label="merchants.label" icon="bx-cart" :value="item.$shipment.merchant_name" />
        </div>
        <div class="col" v-if="item.is_consolidation">
          <value label="packages.consolidations" icon="bx-unite">
            <span
              v-for="(consolidation) in item.$consolidations"
              :key="consolidation.id"
              class="d-block"
            >
              {{consolidation.barcode}}
            </span>
          </value>
        </div>
        <div class="col" v-else-if="item.is_consolidated">
          <value label="packages.consolidated" icon="bx-unite">
            <a 
              @click="close({barcode: item.$meta.consolidated_to.barcode})"
              class="d-block linkish text-info"
            >
              {{item.$meta.consolidated_to.barcode}}
            </a>
          </value>
        </div>
      </div>
    </dl>

    <template #modal-footer style="justify-content: space-between">
      <b-button @click="close(null)">{{$t('common.close')}}</b-button>
      <async-button v-if="item.$meta.actions['extend-expiry']" variant="info" :handler="extendExpiry">
        {{$t(`packages.actions.extend-expiry`)}}
      </async-button>
      <async-button v-if="item.$meta.actions.expire" variant="danger" :handler="expire">
        {{$t(`packages.actions.expire`)}}
      </async-button>
    </template>
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"
import Value from "@/components/Value"
import Package from '@/resources/Package'
import Status from '@/components/Status'
import AsyncButton from "@/components/AsyncButton.vue";

export default {
  name: 'DriverModal',
  extends: Modal,
  components: {Value, Status, AsyncButton},
  props: {
    item: {
      type: Package,
      required: true,
    },
  },
  computed: {
    actions() {
      const actions = []
      if(this.item.$meta.actions.reroute) {
        actions.push('reroute')
      }
      if(this.item.$meta.actions.pickup) {
        actions.push('pickup')
      }
      return actions
    },
    barcode() {
      return `${process.env.VUE_APP_API_HOST}barcode/${this.item.barcode}`
    }
  },
  methods: {
    async extendExpiry() {
      await this.item.$extendExpiry()
      this.close({reload: true})
    },
    async expire() {
      const confirm = await this.$bvModal.msgBoxConfirm(this.$t('packages.actions.expire-confirm-text'),{
        cancelTitle: this.$t('common.cancel'),
        okTitle: this.$t('packages.actions.expire'),
        okVariant: 'danger',
      })

      if(confirm) {
        await this.item.$expire()
        this.close({reload: true})
      }
    },
  }
}
</script>