<template>
  <b-button 
    class="w-100 d-block mb-4 text-left overflow-hidden" 
    v-bind="options"
    v-on="$listeners"
  >
    <i class='bx bx-unite bx-burst float-right text-info' v-if="consolidatable"></i>
    <strong v-if="lockerNumbers" class="float-right">{{item.locker_number}}</strong>
    <i class='bx bx-barcode'></i> {{item.barcode}} <br>
    <small><strong><i class='bx bx-user'></i> {{item.consignee}}</strong></small> <br>
    <template v-if="detail == 'date'">
      <small><i class='bx bx-calendar-event'></i> {{item.updated_at | moment}}</small> <br>
    </template>
    <template v-else-if="detail == 'location'">
      <small><i class='bx bx-map'></i> {{item.dropoff_location_name}}</small> <br>
    </template>
    <template v-else-if="detail == 'expiry'">
      <small v-if="isExpired"><i class='bx bx-calendar-x'></i> {{ $t('packages.expired') }} {{ item.expires_at | age }}</small>
      <small v-else><i class='bx bx-calendar'></i> {{ $t('packages.expires-at') }} {{ item.expires_at | moment('LLL') }}</small> 
      <br>
    </template>
  </b-button>
</template>

<script>
import { STATUS_AWAITING_PAYMENT, STATUS_EXPIRED, STATUS_EXPIRED_COLLECTABLE } from '@/resources/Package'
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    detail: {
      type: [String,Boolean],
      default: 'location'
    },
    lockerNumbers: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isExpired() {
      return [STATUS_EXPIRED,STATUS_EXPIRED_COLLECTABLE].includes(this.item.status)
    },
    options() {
      const options = {
        class: {
          stack: this.item.is_consolidation,
        },
        variant: null,
      }

      switch(this.item.status) {
        case STATUS_EXPIRED: 
          options.variant = 'warning'
          break
        case STATUS_EXPIRED_COLLECTABLE:
          options.variant = 'danger'
          break
        case STATUS_AWAITING_PAYMENT:
          options.variant = 'dark'
          break
        default:
          options.class['bg-white'] = true
          break
      }

      return options
    },
    consolidatable() {
      return this.item.$meta?.consolidatable
    }
  },
}
</script>